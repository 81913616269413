<template>
  <Header/>
  <Hero/>
  <Home/>
  <Footer/>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import Hero from '@/components/Hero.vue';
import Home from '@/components/Home.vue';

export default {
  name: 'App',
  components: {
    Header,
    Hero,
    Home,
    Footer
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
